
import Vue from "vue";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
interface IAdjusterPortalData {
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
}
export default Vue.extend({
  name: "AdjusterPortal",
  components: {
    CustomAlert,
    AdjusterPortalList: () =>
      import("@/components/Adjuster/ListPortal/AdjusterPortalList.vue")
  },
  data(): IAdjusterPortalData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false
    };
  },
  methods: {
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.$router.push(`/admin/agencies/create`).catch(() => {});
          break;
      }
    },
    doSetErrorMessage(error: string): void {
      this.errorMessage = error;
    }
  }
});
